import FlightsSearchControlsGC, {
  navigate,
  type SearchParams,
} from '@skyscanner-internal/global-components/flights-search-controls';
import {
  fetchCalendarPrices,
  fetchMonthPrices,
  fetchSuggestions,
} from '@skyscanner-internal/global-components/flights-search-controls/data';
import logger from 'saddlebag-logger';

import { getIsClient } from './helpers';

import type { FlightsSearchControlsGCWrapperProps } from '@skyscanner-internal/falcon-shared-types/types/FlightsSearchControlsGCWrapperProps';

const FlightsSearchControlsGCWrapper = ({
  cldr,
  culture,
  destination,
  deviceType,
  featureDecisions,
  initialSearchParams,
  origin,
  pageType,
  strings,
}: FlightsSearchControlsGCWrapperProps) => {
  const isClient = getIsClient();

  // Some initial search state copied from Banana:
  // https://github.skyscannertools.net/skyscanner/banana/blob/d22fca58b19c1901debb202cf7095169efa37ee3/packages/webapp/src/state/user/search/initialState.ts#L7
  // with params passed from Falcon server for origin, destination, adults, dates, and trip type
  // which replicate previous behaviour found in the OC.

  // Building of this initial search state is very manual and replicating all previous OC and all
  // new Banana-based GC functionality would not be feasable. We will plan to work with Goose on
  // making building this more convenient, likely with helper functions exposed from the OC
  // to persist user settings where appropriate.

  const initialSearchState: SearchParams = {
    version: '1',
    adults: initialSearchParams?.adults ?? 1,
    child_ages: initialSearchParams?.child_ages ?? [],
    cabinClass: 'ECONOMY',
    legs: [
      {
        origin,
        destination,
        date: initialSearchParams?.departureDate ?? '',
        return_date: initialSearchParams?.returnDate ?? undefined,
      },
    ],
    options: {
      // We have no controls for these currently, so default the options
      include_unpriced_itineraries: true,
      include_mixed_booking_options: true,
    },
    alternativeDestinations: false,
    alternativeOrigins: false,
    state: {},
    // prefer_directs was previously persisted by some local storage via the OC
    // but we don't have access to that in Falcon server, and don't want to replicate
    // client-side local storage in this component
    // https://github.skyscannertools.net/skyscanner/flights-search-controls/blob/9c3ade65042e4cfd4aa09380b7f7174083f8f92d/src/components/App/defaults.ts#L252-L253
    prefer_directs: false,
    tripType: initialSearchParams?.tripType ?? 'RETURN',
  };

  const isProduction = process.env.NODE_ENV === 'production';

  return isClient ? (
    <FlightsSearchControlsGC
      onSearch={navigate}
      cldr={cldr}
      culture={culture}
      strings={strings}
      fetchCalendarPrices={fetchCalendarPrices}
      fetchMonthPrices={fetchMonthPrices}
      fetchSuggestions={fetchSuggestions}
      initialSearchState={initialSearchState}
      logger={logger}
      featureDecisions={featureDecisions}
      trackingProps={{
        platform: deviceType,
        screenName: pageType,
      }}
      xSkyscannerClient="falcon"
      isProductionEnv={isProduction}
    />
  ) : null;
};

export default FlightsSearchControlsGCWrapper;
