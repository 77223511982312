import classNames from 'classnames';

import DeferOC from '../DeferOC';

type FlightsSearchControlsOCProps = {
  searchWrapperClasses: classNames.ArgumentArray;
  useDeferOC?: boolean;
  flightsHtmlContent: string;
};

const FlightsSearchControlsOC = ({
  flightsHtmlContent,
  searchWrapperClasses,
  useDeferOC = false,
}: FlightsSearchControlsOCProps) => (
  <div
    data-testid="flights-search-controls"
    className={classNames(searchWrapperClasses)}
  >
    {useDeferOC ? (
      <DeferOC html={flightsHtmlContent} />
    ) : (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: flightsHtmlContent }} />
    )}
  </div>
);

export default FlightsSearchControlsOC;
